import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AccordionCard from './AccordionCard/AccordionCard';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import MultilineChartOutlinedIcon from '@mui/icons-material/MultilineChartOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import LocalFloristOutlinedIcon from '@mui/icons-material/LocalFloristOutlined';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import SatelliteAltOutlinedIcon from '@mui/icons-material/SatelliteAltOutlined';

const offersData = [
    {
        id: 1,
        title: 'A Levels',
        subjects: [
            { name: "Biology", icon: <BiotechOutlinedIcon /> },
            { name: "Economics", icon: <MultilineChartOutlinedIcon /> },
            { name: "Geography", icon: <TravelExploreOutlinedIcon /> },
            { name: "Computer Science", icon: <ComputerOutlinedIcon /> }
        ],
        bordercolor: "#1eb2a6",
        bgcolor: "#e8fbf9"
    },
    {
        id: 2,
        title: 'GCSE/IGCSE',
        subjects: [
            { name: "General Science", icon: <ScienceOutlinedIcon /> },
            { name: "Combined Science", icon: <ScienceOutlinedIcon /> },
            { name: "Tripple Science", icon: <ScienceOutlinedIcon /> },
            { name: "Maths", icon: <SquareFootOutlinedIcon /> },
            { name: "English", icon: <AbcOutlinedIcon /> },
            { name: "Geography", icon: <TravelExploreOutlinedIcon /> },
            { name: "Computer Science and ICT", icon: <ComputerOutlinedIcon /> }
        ],
        bordercolor: "#a6a6a6",
        bgcolor: "#f4f4f4"
    },
    {
        id: 3,
        title: 'AP',
        subjects: [
            { name: "AP Biology", icon: <BiotechOutlinedIcon /> },
            { name: "AP Environmental Science", icon: <LocalFloristOutlinedIcon /> },
            { name: "AP Economics", icon: <MultilineChartOutlinedIcon /> }
        ],
        bordercolor: "#1eb2a6",
        bgcolor: "#e8fbf9"
    },
    {
        id: 4,
        title: 'High School (US)',
        subjects: [
            { name: "Biology", icon: <BiotechOutlinedIcon /> },
            { name: "Economics", icon: <MultilineChartOutlinedIcon /> },
            { name: "Geography", icon: <TravelExploreOutlinedIcon /> },
            { name: "Maths", icon: <SquareFootOutlinedIcon /> }
        ],
        bordercolor: "#a6a6a6",
        bgcolor: "#f4f4f4"
    },
    {
        id: 5,
        title: 'High School (Australia)',
        subjects: [
            { name: "K10 & K12", icon: <LibraryBooksTwoToneIcon /> },
            { name: "Biology", icon: <BiotechOutlinedIcon /> },
            { name: "Maths", icon: <SquareFootOutlinedIcon /> },
            { name: "Science", icon: <ScienceOutlinedIcon /> }
        ],
        bordercolor: "#1eb2a6",
        bgcolor: "#e8fbf9"
    },
    {
        id: 6,
        title: 'CBSE',
        subjects: [
            { name: "Physics", icon: <SatelliteAltOutlinedIcon /> },
            { name: "Chemistry", icon: <ScienceOutlinedIcon /> },
            { name: "Maths", icon: <SquareFootOutlinedIcon /> },
            { name: "Biology", icon: <BiotechOutlinedIcon /> }
        ],
        bordercolor: "#a6a6a6",
        bgcolor: "#f4f4f4"
    },
    {
        id: 7,
        title: 'IB Curriculum',
        subjects: [
            { name: "Biology", icon: <BiotechOutlinedIcon /> },
            { name: "Maths", icon: <SquareFootOutlinedIcon /> }
        ],
        bordercolor: "#1eb2a6",
        bgcolor: "#e8fbf9"
    },
];

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ bordercolor }) => ({
    borderLeft: `4px solid ${bordercolor}`,
    borderRadius: '5px',
    marginBottom: '8px',
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme, bgcolor }) => ({
    backgroundColor: `${bgcolor}`,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme, bgcolor }) => ({
    padding: theme.spacing(2),
    backgroundColor: `${bgcolor}`,
}));

const CustomizedAccordions = () => {
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className="mb-16">
            {
                offersData.map((offer) => {
                    return (
                        <Accordion bordercolor={offer.bordercolor} key={offer.id} expanded={expanded === `panel${offer.id}`} onChange={handleChange(`panel${offer.id}`)}>
                            <AccordionSummary bgcolor={offer.bgcolor} aria-controls={`panel${offer.id}d-content`} id={`panel${offer.id}d-header`}>
                                <h3 className='text-xl text-font_one font-semibold py-2'>{offer.title}</h3>
                            </AccordionSummary>
                            <AccordionDetails bgcolor={offer.bgcolor}>
                                <div className='grid desktop:grid-cols-3 mediumTablet:grid-cols-2 smallPhone:grid-cols-1 wideScreen:gap-10 desktop:gap-8 largeTablet:gap-5 mediumTablet:gap-4 smallPhone:gap-5'>
                                    {
                                        offer.subjects.map((subject, index) => {
                                            return (
                                                <AccordionCard key={index} cardName={subject.name} icon={subject.icon} bordercolor={offer.bordercolor} />
                                            );
                                        })
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            }
        </div>
    );
}

export default CustomizedAccordions;