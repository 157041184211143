import { NavLink } from 'react-router-dom';

const CourseCard = ({ courseImage, courseName, description, instructorImage, instructorName, price, off, duration, mode, aos }) => {
    return (
        <NavLink to="https://wa.me/+917051144137" target="_blank" className="w-full" data-aos={aos}>
            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px' }} className="flex largePhone:h-[14.5rem] smallPhone:h-[27rem] largePhone:flex-row smallPhone:flex-col largePhone:justify-center largePhone:items-stretch smallPhone:items-center rounded cursor-pointer transition ease-in-out delay-150 hover:scale-105">
                <img className="desktop:w-60 largeTablet:w-48 mediumTablet:w-72 largePhone:w-48 smallPhone:w-full largePhone:rounded-l smallPhone:rounded-t" src={courseImage} alt={`TheChosenField ${courseName} course`} />
                <div className="h-full bg-white flex justify-center flex-col smallTablet:p-4 smallPhone:p-3 smallPhone:w-full">
                    <h3 className="desktop:text-xl largeTablet:text-lg smallTablet:text-xl smallPhone:text-lg font-medium text-font_one">{courseName}</h3>
                    <p className="mt-1 h-full desktop:text-sm largeTablet:text-xs smallTablet:text-sm smallPhone:text-xs text-font_two font-medium">{description}</p>
                    {/* <div className="flex items-center">
                        <img className="desktop:w-8 largeTablet:w-6 smallTablet:w-7 smallPhone:w-7 desktop:h-8 largeTablet:h-6 smallTablet:h-7 smallPhone:h-6 rounded-full mr-3" src={instructorImage} alt={`TheChosenField instructor ${instructorName}`} />
                        <span className="desktop:text-sm largeTablet:text-xs smallTablet:text-sm smallPhone:text-xs mr-1">by</span>
                        <span className="desktop:text-sm largeTablet:text-xs smallTablet:text-sm smallPhone:text-xs font-semibold">{instructorName}</span>
                    </div> */}

                    <div className="my-1 dektop:text-xl largeTablet:text-lg smallTablet:text-xl smallPhone:text-lg font-semibold">
                        {/* <span className="text-primary mr-5">₹ {price}</span>
                        <strike className="opacity-50">₹ {parseInt(price) + parseInt(off)}</strike> */}
                        <span className="text-primary">First Session Free</span>
                    </div>

                    <div className="border-t-2 border-[#bdbdbd] desktop:text-sm largeTablet:text-xs smallTablet:text-sm smallPhone:text-xs pt-2" >
                        <span className="text-font_two">Mode: <b>{mode}</b></span>
                    </div>
                </div>
            </div>
        </NavLink>
    );
}

export default CourseCard;