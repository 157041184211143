import { Alert, Checkbox, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import Brand from "../../components/Header/Brand/Brand";
import Header from "../../components/Header/Header";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useRef, useState } from "react";
import dayjs from 'dayjs';

const TutoringEnquire = () => {
    const initialFormValues = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        subjectStrugglingIn: [],
        curriculum: [],
        contactMethod: [],
        preferredDemoDay: dayjs(),
        formSubmitted: false,
        success: false
    }

    const [subjectStrugglingInCheckboxes, setSubjectStrugglingInCheckboxes] = useState({
        Maths: false,
        Science: false,
        EnvironmentalScience: false,
        English: false,
        Economics: false,
        ICTandComputerScience: false
    });

    const [curriculumCheckboxes, setCurriculumCheckboxes] = useState({
        GCSE: false,
        IGCSE: false,
        AP: false,
        ALevel: false,
        HighSchool: false
    });

    const [contactMethodCheckboxes, setContactMethodCheckboxes] = useState({
        Phone: false,
        Email: false,
        Whatsapp: false
    });

    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});

    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState("success");

    const [loading, setLoading] = useState(false);

    // Create refs for each input field
    const fullNameRef = useRef();
    const emailRef = useRef();
    const phoneNumberRef = useRef();
    const subjectStrugglingInRef = useRef();
    const curriculumRef = useRef();
    const contactMethodRef = useRef();

    const formRef = useRef();

    const handleInputValue = (e) => {
        const { name, value } = e.target;

        // Use a functional state update to ensure you're working with the most up-to-date state
        setValues((prevState) => ({
            ...prevState,
            [name]: value
        }));

        // Validate using the new value
        validate({ [name]: value });
    }

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;

        // set the checkbox state
        setSubjectStrugglingInCheckboxes((prevState) => ({
            ...prevState,
            [value]: checked
        }));

        setCurriculumCheckboxes((prevState) => ({
            ...prevState,
            [value]: checked
        }));

        setContactMethodCheckboxes((prevState) => ({
            ...prevState,
            [value]: checked
        }));

        // Update state using the previous state to ensure real-time updates
        setValues((prevState) => {
            const updatedArray = checked
                ? [...(prevState[name] || []), value] // Add value if checked
                : prevState[name].filter((item) => item !== value); // Remove value if unchecked

            return {
                ...prevState,
                [name]: updatedArray,
            };
        });

        validate({ [name]: checked ? [...(values[name] || []), value] : values[name].filter(item => item !== value) });
    };

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        // Validate firstName
        if ("firstName" in fieldValues)
            temp.firstName = fieldValues.firstName ? "" : "First name is required.";

        // Validate lastName
        if ("lastName" in fieldValues)
            temp.lastName = fieldValues.lastName ? "" : "Last name is required.";

        // Validate email
        if ("email" in fieldValues) {
            temp.email = fieldValues.email ? "" : "Email is required.";
            if (fieldValues.email)
                temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
                    ? ""
                    : "Email is not valid.";
        }

        // Validate phoneNumber
        if ("phoneNumber" in fieldValues) {
            temp.phoneNumber = fieldValues.phoneNumber ? "" : "Phone number is required.";
            if (fieldValues.phoneNumber)
                temp.phoneNumber = /^[0-9]{10}$/.test(fieldValues.phoneNumber)
                    ? ""
                    : "Phone number is not valid (must be 10 digits).";
        }

        // Validate subjectStrugglingIn
        if ("subjectStrugglingIn" in fieldValues)
            temp.subjectStrugglingIn = fieldValues.subjectStrugglingIn.length > 0
                ? ""
                : "Please select at least one subject.";

        // Validate curriculum
        if ("curriculum" in fieldValues)
            temp.curriculum = fieldValues.curriculum.length > 0
                ? ""
                : "Please select at least one curriculum.";

        // Validate contactMethod
        if ("contactMethod" in fieldValues)
            temp.contactMethod = fieldValues.contactMethod.length > 0
                ? ""
                : "Please select at least one preferred contact method.";

        setErrors({
            ...temp
        });

        // Return true if no errors exist in temp
        return Object.values(temp).every(x => x === "");
    }

    const formIsValid = (fieldValues = values) => {
        let valid = true;

        // First validate all fields
        validate(fieldValues);

        // Then check all fields are filled out properly and errors are empty
        valid =
            values.firstName &&
            values.lastName &&
            values.email &&
            values.phoneNumber &&
            values.subjectStrugglingIn.length > 0 &&
            values.curriculum.length > 0 &&
            values.contactMethod.length > 0 &&
            // values.preferredDemoDay &&
            Object.values(errors).every((x) => x === "");

        return valid;
    };

    const resetForm = () => {
        setValues({
            ...initialFormValues
        });

        // unchecked all checkboxes
        const uncheckedSubjectStrugglingInCheckboxes = Object.keys(subjectStrugglingInCheckboxes).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});

        const uncheckedCurriculumCheckboxes = Object.keys(curriculumCheckboxes).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});

        const uncheckedContactMethodCheckboxes = Object.keys(contactMethodCheckboxes).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});

        setSubjectStrugglingInCheckboxes(uncheckedSubjectStrugglingInCheckboxes);
        setCurriculumCheckboxes(uncheckedCurriculumCheckboxes);
        setContactMethodCheckboxes(uncheckedContactMethodCheckboxes);

        // reset errors
        setErrors({});

        // Scroll to the top of the form
        formRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const handleFormSubmit = (e) => {
        setLoading(true);
        e.preventDefault();

        // Run validation on all fields before submitting
        if (validate(values) && formIsValid()) {
            console.log(values);

            // Show success alert
            setAlertMessage("Form submitted successfully! We will contact you shortly.");
            setAlertSeverity("success");
            setValues({ ...values, formSubmitted: true, success: true });
            setTimeout(() => {
                setValues({ ...initialFormValues, success: false });
            }, 5000);
            resetForm();
            setLoading(false);
        } else {
            // Show error alert with validation errors
            setAlertMessage("Form submission failed. Please fill out all required fields.");
            setAlertSeverity("error");
            // Scroll to the first errored field
            if (errors.firstName) fullNameRef.current.scrollIntoView({ behavior: "smooth" });
            else if (errors.lastName) fullNameRef.current.scrollIntoView({ behavior: "smooth" });
            else if (errors.email) emailRef.current.scrollIntoView({ behavior: "smooth" });
            else if (errors.phoneNumber) phoneNumberRef.current.scrollIntoView({ behavior: "smooth" });
            else if (errors.subjectStrugglingIn) subjectStrugglingInRef.current.scrollIntoView({ behavior: "smooth" });
            else if (errors.curriculum) curriculumRef.current.scrollIntoView({ behavior: "smooth" });
            else if (errors.contactMethod) contactMethodRef.current.scrollIntoView({ behavior: "smooth" });

            setLoading(false);
        }
        // Hide the alert after 5 seconds
        setTimeout(() => {
            setAlertMessage(null);
        }, 5000);
    }

    return (
        <>
            <section>
                <Header>
                    <Brand />
                </Header>
            </section>

            <section ref={formRef} className="flex items-center justify-center">
                <div className="fixed z-10 top-10 desktop:right-10 mediumTablet:right-5 smallTablet:right-2 largePhone:right-5 smallPhone:right-2 smallTablet:left-auto largePhone:left-5 smallPhone:left-2 text-center">
                    {alertMessage && (
                        <Alert variant="filled" severity={alertSeverity} onClose={() => setAlertMessage(null)}>
                            {alertMessage}
                        </Alert>
                    )}
                </div>
                <div style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px" }} className="largeTablet:my-20 mediumTablet:my-16 smallTablet:my-14 largePhone:my-12 smallPhone:my-10 largeTablet:p-10 mediumTablet:p-8 smallTablet:p-7 largePhone:p-6 smallPhone:p-5 rounded-md desktop:w-2/3 largeTablet:w-5/6 smallTablet:w-11/12 smallPhone:w-[95%]">
                    <h2 className="smallTablet:text-3xl smallPhone:text-2xl text-font_one font-medium mb-3">Tutoring Enquire</h2>
                    <p className="smallTablet:text-base smallPhone:text-sm text-font_two opacity-80 mb-8">Once we receive the filed form, we will contact you shortly to discuss further about the demo.</p>

                    <form onSubmit={handleFormSubmit}>
                        <div ref={fullNameRef} className="space-y-5">
                            <div>
                                <label className="smallTablet:text-base smallPhone:text-sm" htmlFor="full-name">Student Full Name *</label>
                                <div id="full-name" className="smallTablet:space-x-5 smallTablet:space-y-0 smallPhone:space-y-5 flex smallTablet:flex-row smallPhone:flex-col mt-2">
                                    <TextField
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        name="firstName"
                                        value={values.firstName}
                                        {...(errors.firstName && { error: true, helperText: errors.firstName })}
                                        label="First Name"
                                        fullWidth
                                        autoComplete="none"
                                    />
                                    <TextField
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        name="lastName"
                                        value={values.lastName}
                                        {...(errors.lastName && { error: true, helperText: errors.lastName })}
                                        label="Last Name"
                                        fullWidth
                                        autoComplete="none"
                                    />
                                </div>
                            </div>
                            <div ref={emailRef}>
                                <label className="smallTablet:text-base smallPhone:text-sm" htmlFor="email">Parent's Email or Student's Email *</label>
                                <div className="mt-2" id="email">
                                    <TextField
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        name="email"
                                        value={values.email}
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email : "Your Information is confidential at TheChosenFIeld Academy!"}
                                        label="Email"
                                        fullWidth
                                        autoComplete="none"
                                    />
                                </div>
                            </div>
                            <div ref={phoneNumberRef}>
                                <label className="smallTablet:text-base smallPhone:text-sm" htmlFor="phone-number">Parent contact number *</label>
                                <div className="mt-2" id="phone-number">
                                    <TextField
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        error={!!errors.phoneNumber}
                                        helperText={errors.phoneNumber ? errors.phoneNumber : "Your Information is confidential at TheChosenFIeld Academy!"}
                                        label="Phone Number"
                                        fullWidth
                                        autoComplete="none"
                                    />
                                </div>
                            </div>
                            <div ref={subjectStrugglingInRef}>
                                <label className="smallTablet:text-base smallPhone:text-sm" htmlFor="subject-struggling-in">Subject Struggling in *</label>
                                <div className="mt-2 flex flex-col" id="subject-struggling-in">
                                    <FormControlLabel
                                        control={<Checkbox value="Maths" name="subjectStrugglingIn" checked={subjectStrugglingInCheckboxes.Maths} onChange={handleCheckboxChange} />}
                                        label="Maths"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="Science" name="subjectStrugglingIn" checked={subjectStrugglingInCheckboxes.Science} onChange={handleCheckboxChange} />}
                                        label="Science"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="EnvironmentalScience" name="subjectStrugglingIn" checked={subjectStrugglingInCheckboxes.EnvironmentalScience} onChange={handleCheckboxChange} />}
                                        label="Environmental Science"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="English" name="subjectStrugglingIn" checked={subjectStrugglingInCheckboxes.English} onChange={handleCheckboxChange} />}
                                        label="English"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="Economics" name="subjectStrugglingIn" checked={subjectStrugglingInCheckboxes.Economics} onChange={handleCheckboxChange} />}
                                        label="Economics"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="ICTandComputerScience" name="subjectStrugglingIn" checked={subjectStrugglingInCheckboxes.ICTandComputerScience} onChange={handleCheckboxChange} />}
                                        label="ICT and Computer Science"
                                    />
                                </div>
                                <FormHelperText error={!!errors.subjectStrugglingIn}>{errors.subjectStrugglingIn ? errors.subjectStrugglingIn : ""}</FormHelperText>
                            </div>
                            <div ref={curriculumRef}>
                                <label className="smallTablet:text-base smallPhone:text-sm" htmlFor="curriculum">Curriculum *</label>
                                <div className="mt-2 flex flex-col" id="curriculum">
                                    <FormControlLabel
                                        control={<Checkbox value="GCSE" name="curriculum" checked={curriculumCheckboxes.GCSE} onChange={handleCheckboxChange} />}
                                        label="GCSE"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="IGCSE" name="curriculum" checked={curriculumCheckboxes.IGCSE} onChange={handleCheckboxChange} />}
                                        label="IGCSE"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="AP" name="curriculum" checked={curriculumCheckboxes.AP} onChange={handleCheckboxChange} />}
                                        label="AP"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="ALevel" name="curriculum" checked={curriculumCheckboxes.ALevel} onChange={handleCheckboxChange} />}
                                        label="A Level"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="HighSchool" name="curriculum" checked={curriculumCheckboxes.HighSchool} onChange={handleCheckboxChange} />}
                                        label="High School"
                                    />
                                </div>
                                <FormHelperText error={!!errors.curriculum}>{errors.curriculum ? errors.curriculum : ""}</FormHelperText>
                            </div>
                            <div ref={contactMethodRef}>
                                <label className="smallTablet:text-base smallPhone:text-sm" htmlFor="contact-method">Preferred contact method *</label>
                                <div className="mt-2 flex flex-col" id="contact-method">
                                    <FormControlLabel
                                        control={<Checkbox value="Phone" name="contactMethod" checked={contactMethodCheckboxes.Phone} onChange={handleCheckboxChange} />}
                                        label="Phone"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="Email" name="contactMethod" checked={contactMethodCheckboxes.Email} onChange={handleCheckboxChange} />}
                                        label="Email"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="Whatsapp" name="contactMethod" checked={contactMethodCheckboxes.Whatsapp} onChange={handleCheckboxChange} />}
                                        label="Whatsapp"
                                    />
                                </div>
                                <FormHelperText error={!!errors.contactMethod}>{errors.contactMethod ? errors.contactMethod : "You will be contacted by the admin to discuss further about the demo!"}</FormHelperText>
                            </div>
                            <div>
                                <label className="smallTablet:text-base smallPhone:text-sm" htmlFor="date-time">Preferred Demo Day *</label>
                                <div className="mt-2" id="date-time">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label="Select Date & Time"
                                            value={values.preferredDemoDay || dayjs()}
                                            onChange={(newValue) => {
                                                setValues({ ...values, preferredDemoDay: newValue });
                                            }}
                                            defaultValue={dayjs()}
                                            disablePast
                                            slotProps={{ textField: { variant: 'outlined', error: false } }}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <FormHelperText>Select your preferred day and time.</FormHelperText>
                            </div>
                        </div>

                        <div className="mt-8 flex items-center justify-end">
                            <button type="reset" onClick={resetForm} className="text-font_two bg-white mediumTablet:text-lg largePhone:text-base smallPhone:text-sm font-medium mediumTablet:py-3 smallPhone:py-2 rounded largeTablet:w-36 smallTablet:w-32 smallPhone:w-28 transition ease-in-out delay-150 border-2 border-white hover:border-primary hover:text-primary hover:border-2 mr-5">Clear</button>
                            <button type="submit" className="text-white bg-primary self-start mediumTablet:text-lg largePhone:text-base smallPhone:text-sm font-medium mediumTablet:py-3 smallPhone:py-2 largeTablet:w-1/3 mediumTablet:w-2/5 smallTablet:w-5/12 smallPhone:w-1/2 rounded transition ease-in-out delay-150 hover:bg-[#19a095]">
                                Enquire Now
                            </button>
                            {loading && (<div className="ml-2 desktop:w-10 largeTablet:w-8 mediumTablet:w-10 largePhone:w-8 smallPhone:w-6 desktop:h-10 largeTablet:h-8 mediumTablet:h-10 largePhone:h-8 smallPhone:h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#1EB2A6"></stop><stop offset=".3" stopColor="#1EB2A6" stopOpacity=".9"></stop><stop offset=".6" stopColor="#1EB2A6" stopOpacity=".6"></stop><stop offset=".8" stopColor="#1EB2A6" stopOpacity=".3"></stop><stop offset="1" stopColor="#1EB2A6" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" strokeWidth="23" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#1EB2A6" strokeWidth="23" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>
                            </div>)}
                            {values.success && (<i className="fa-solid fa-circle-check desktop:text-3xl largeTablet:text-2xl mediumTablet:text-3xl largePhone:text-2xl smallPhone:text-xl text-primary ml-2"></i>)}
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}

export default TutoringEnquire;