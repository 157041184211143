import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import { NavLink } from 'react-router-dom';

const AccordionCard = ({ cardName, icon, bordercolor }) => {
    return (
        <NavLink to="https://wa.me/+917051144137" target="_blank" className="w-full">
            <div className='flex items-center rounded bg-white shadow-md transition ease-in delay-150 hover:bg-[#ebebeb]'>
                <div style={{ backgroundColor: bordercolor }} className={`p-5 rounded-s text-white`}>
                    {icon ? icon : <LibraryBooksTwoToneIcon />}
                </div>
                <div className='text-lg text-font_one font-semibold px-5'>{cardName}</div>
            </div>
        </NavLink>
    )
}

export default AccordionCard;