import Marquee from "react-fast-marquee";

const MarqueeSlider = () => {

    return (
        <Marquee className="select-none py-5 overflow-hidden mediumTablet:text-xl smallPhone:text-lg font-bold bg-white text-font_one cursor-default" pauseOnHover="true" speed={100} gradient="true" gradientColor="#F8F8F8" gradientWidth={100}>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Biology</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Economics</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Geography</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Computer Science</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Maths</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Science</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">English</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Physics</div>
            <div className="largeTablet:mx-12 mediumTablet:mx-7 smallTablet:mx-5 largePhone:mx-3 smallPhone:mx-2 p-2 hover:text-primary transition ease-in-out delay-100">Chemistry</div>
        </Marquee>
    );
}

export default MarqueeSlider;