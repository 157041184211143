import Section from "../Section/Section";
import InfographicsCard from "./InfographicsCard/InfographicsCard";

const InfographicsCounter = () => {
    return (
        <Section heading="Success In Numbers" subHeading="Our Experience in Education Industry" bgColor="#f8f8f8">
            <div className="grid largeTablet:grid-cols-3 largePhone:grid-cols-2 smallPhone:grid-cols-1 mediumTablet:gap-8 smallTablet:gap-6 smallPhone:gap-5 w-full">
                <InfographicsCard image="countries-covered.png" number="20" text="Countries Covered" />
                <InfographicsCard image="curriculums-covered.png" number="10" text="Curriculums Covered" />
                <InfographicsCard image="hours-taught.png" number="5000" text="Hours Taught" />
                <InfographicsCard image="industry-experience.png" number="10" text="Years of Industry Experience" />
                <InfographicsCard image="average-rating.png" number="4.7" text="Average Rating" />
            </div>
        </Section>
    )
}

export default InfographicsCounter;