import Section from "../Section/Section";
import WhyCard from "./WhyCard/WhyCard";

const WhyTheChosenField = () => {
    return (
        <Section heading="WHY TheChosenField ?" subHeading={`Making learning easier and more convenient for you`} bgColor="#fff">
            <div className="grid largeTablet:grid-cols-3 largePhone:grid-cols-2 smallPhone:grid-cols-1 desktop:gap-6 largeTablet:gap-4 smallPhone:gap-3">
                <WhyCard aos="fade-right" icon="fa-solid fa-chalkboard-user" color="#073b4c" heading="One-on-One Virtual Classes" paragraph="Student-friendly, and Growth oriented sessions with an exceptional teaching approach." />
                <WhyCard aos="fade-up" icon="fa-solid fa-person-chalkboard" color="#f78c6b" heading="Teachers with decade of Expertise" paragraph="Reliable teachers with near to a decade of expertise in GCSE IB AP and Premedical Exams." />
                <WhyCard aos="fade-left" icon="fa-solid fa-book-open-reader" color="#ef476f" heading="Student Friendly Lessons" paragraph="Learn at your own pace at your own comfort, It's just you and the teacher, Because we know you need attention." />
                <WhyCard aos="fade-right" icon="fa-solid fa-pencil" color="#ffd166" heading="Exam Oriented" paragraph="Past papers infused learning, with proper lesson plans and Exam-oriented preparations." />
                <WhyCard aos="fade-up" icon="fa-solid fa-folder-open" color="#06d6a0" heading="Affordable Packages" paragraph="Elegant discount on Long-term Gold and Elite Packages, Pack of 20 and Pack of 50 sessions." />
                <WhyCard aos="fade-left" icon="fa-solid fa-headset" color="#118ab2" heading="Extra Support" paragraph="Expert Faculty is always available in the dedicated groups to tackle all the doubts so that nothing is left behind." />
            </div>
        </Section>
    );
}

export default WhyTheChosenField;