import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay, FreeMode } from "swiper/modules";

const Slider = ({ slidesPerView, sliderClass, autoPlay, children, spaceBetween, arrowLeftPosition, arrowLeftStyle, arrowRightPosition, arrowRightStyle, height }) => {
    return (
        <div className='relative'>
            <div className={`flex justify-center items-center ${height}`}>
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={spaceBetween}
                    navigation={{ nextEl: "#arrow-left", prevEl: "#arrow-right" }}
                    speed={1000}
                    modules={[Navigation, Autoplay, FreeMode]}
                    freeMode={true}
                    loop={true}
                    grabCursor={true}
                    className={`mySwiper ${sliderClass}`}
                    autoplay={autoPlay ? {
                        delay: 3000,
                        pauseOnMouseEnter: true,
                    } : false}
                >
                    {children}
                </Swiper >
            </div>
            <i id="arrow-left" className={`fa-solid fa-chevron-left absolute text-2xl opacity-70 hover:opacity-100 cursor-pointer ${arrowLeftStyle}`} style={{ left: arrowLeftPosition }} ></i>
            <i id="arrow-right" className={`fa-solid fa-chevron-right absolute text-2xl opacity-70 hover:opacity-100 cursor-pointer ${arrowRightStyle}`} style={{ right: arrowRightPosition }} ></i>
        </div>
    );
}

export default Slider;