import PlayButtonStyle from './PlayButton.module.css';
import Header from '../../components/Header/Header';
import Brand from '../../components/Header/Brand/Brand';
import { NavLink } from 'react-router-dom';
import Quote from '../../components/shared/Quote/Quote';
import { useEffect, useRef, useState } from 'react';
import LoadModel from '../../components/LoadModal/LoadModal';
import ScrollToTopNavLink from '../../components/ScrollToTopNavLink/ScrollToTopNavLink';

const About = () => {
    const imageScaleUp = {
        transform: "scale(1.2)",
        transition: "all 0.5s ease-in-out"
    }

    const imageScaleDown = {
        transform: "scale(0.9)",
        transition: "all 0.5s ease-in-out"
    }

    const imageScaleNormal = {
        transform: "scale(none)",
        transition: "all 0.5s ease-in-out"
    }

    const sectionRef = useRef(null);
    const firstParaRef = useRef(null);
    const secondParaRef = useRef(null);
    const thirdParaRef = useRef(null);
    const paraStartRef = useRef(null);


    const [showAboutVideo, setShowAboutVideo] = useState(false);
    const [sectionPosition, setSectionPosition] = useState("top");
    const [firstImageStyle, setFirstImageStyle] = useState(imageScaleNormal);
    const [secondImageStyle, setSecondImageStyle] = useState(imageScaleNormal);
    const [thirdImageStyle, setThirdImageStyle] = useState(imageScaleNormal);
    const [ourStoryYear, setOurStoryYear] = useState("2019");

    const ourStoryData = [
        {
            "year": "2019",
            "image": "/images/our-story/our-story-2019.jpg",
            "title": "The Beginning",
            "description": "Sajad kicked off tutoring kiddos as a side hustle, paving the way to his grand master plan."
        },
        {
            "year": "2020",
            "image": "/images/our-story/our-story-2020.png",
            "title": "Expanding Expertise",
            "description": "Sajad leveled up his professional game, playing the role of the wise tutor in various gigs, dropping knowledge bombs in a variety of subjects."
        },
        {
            "year": "2023",
            "image": "/images/our-story/our-story-2023.jpg",
            "title": "The Founding of TheChosenField Academy",
            "description": "The spark of The Whole Idea ignited TheChosenField Academy, with Mr. Sajad working tirelessly day and night, adding more ideas and subjects into the mix."
        },
        {
            "year": "present",
            "image": "/images/our-story/our-story-present.jpg",
            "title": "Global Reach and Multi-Subject Excellence",
            "description": "TheChosenField Academy is currently offering Multiple Subjects and has a significant position Internationally."
        }
    ]

    const ourStoryActiveStyle = "text-white bg-primary largeTablet:font-semibold smallTablet:font-medium smallPhone:font-normal desktop:text-2xl largeTablet:text-[1.3rem] mediumTablet:text-[1.2rem] smallTablet:text-[1.1rem] largePhone:text-[1rem] smallPhone:text-[0.9rem] largeTablet:py-3 smallTablet:py-2 smallPhone:py-1 desktop:px-10 largeTablet:px-9 mediumTablet:px-8 smallTablet:px-7 largePhone:px-6 smallPhone:px-5 rounded-full cursor-pointer";
    const ourStoryInactiveStyle = "text-font_one bg-[#b9d8f9] mediumTablet:font-medium smallPhone:font-normal desktop:text-xl largeTablet:text-[1.1rem] mediumTablet:text-[1rem] smallTablet:text-[0.9rem] largePhone:text-[0.8rem] smallPhone:text-[0.7rem] largeTablet:py-2 mediumTablet:py-[7px] smallTablet:py-[6px] smallPhone:py-[5px] desktop:px-8 largeTablet:px-7 mediumTablet:px-5 smallTablet:px-4 smallPhone:px-3 rounded-full cursor-pointer";

    useEffect(() => {
        const handleImagePosition = () => {
            if (sectionRef.current) {
                const rect = sectionRef.current.getBoundingClientRect();
                if (rect.top > 0) {
                    setSectionPosition("top");
                } else if (rect.top <= 0 && rect.bottom > window.innerHeight) {
                    setSectionPosition("middle");
                } else {
                    setSectionPosition("bottom");
                }
            }

            if (window.innerWidth < 1024) {
                setSectionPosition("none");
            }
        };

        const handleImageScale = () => {
            if (firstParaRef.current) {
                const rect = firstParaRef.current.getBoundingClientRect();
                if (rect.top < window.innerHeight) {
                    setFirstImageStyle(imageScaleUp);
                    setSecondImageStyle(imageScaleDown);
                    setThirdImageStyle(imageScaleDown);
                }
            }

            if (secondParaRef.current) {
                const rect = secondParaRef.current.getBoundingClientRect();
                if (rect.top < window.innerHeight) {
                    setFirstImageStyle(imageScaleDown);
                    setSecondImageStyle(imageScaleUp);
                    setThirdImageStyle(imageScaleDown);
                }
            }

            if (thirdParaRef.current) {
                const rect = thirdParaRef.current.getBoundingClientRect();
                if (rect.top < window.innerHeight) {
                    setFirstImageStyle(imageScaleDown);
                    setSecondImageStyle(imageScaleDown);
                    setThirdImageStyle(imageScaleUp);
                }
            }

            if (window.innerWidth < 1024) {
                setFirstImageStyle(imageScaleNormal);
                setSecondImageStyle(imageScaleNormal);
                setThirdImageStyle(imageScaleNormal);
            }
        };

        window.addEventListener('scroll', handleImagePosition);
        window.addEventListener('scroll', handleImageScale);
        handleImagePosition();
        handleImageScale();

        return () => {
            window.removeEventListener('scroll', handleImagePosition);
            window.removeEventListener('scroll', handleImageScale);
        }
    }, [window.innerWidth]);

    const handleShowAboutVideo = () => {
        setShowAboutVideo(current => !current);
    }

    const handlCloseAboutVideo = (value) => {
        setShowAboutVideo(!value);
    }

    return (
        <>
            <section>
                <Header>
                    <Brand />
                </Header>
            </section>

            <section className='desktop:h-screen wideScreen:px-36 desktop:px-28 largeTablet:px-16 largePhone:px-8 smallPhone:px-5 desktop:py-0 largeTablet:py-16 mediumTablet:py-14 smallTablet:py-12 largePhone:py-10 smallPhone:py-8 flex items-center justify-center bg-white'>
                {showAboutVideo &&
                    <LoadModel delay={0} modelClose={handlCloseAboutVideo} height={(window.innerWidth < 768 && window.innerWidth >= 600) ? '46%' : (window.innerWidth < 600 && window.innerWidth >= 480) ? '38%' : (window.innerWidth < 480 && window.innerWidth >= 320) ? '33%' : '65%'}>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/eLA3HifpbQI?si=lkeAuo7PiTpUoERQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </LoadModel>
                }
                <div className='flex largeTablet:flex-row smallPhone:flex-col wideScreen:space-x-16 desktop:space-x-12 largeTablet:space-x-10 largeTablet:space-y-0 smallTablet:space-y-12 largePhone:space-y-10 smallPhone:space-y-8 items-center'>
                    <div className='largeTablet:w-[48%] largeTablet:text-left smallPhone:text-center'>
                        <span className='block largeTablet:text-4xl smallTablet:text-3xl largePhone:text-2xl smallPhone:text-[1.3rem] font-light largeTablet:mb-4 smallTablet:mb-3 largePhone:mb-2 smallPhone:mb-1'>Hey!</span>
                        <span className='block largeTablet:text-4xl smallTablet:text-3xl largePhone:text-2xl smallPhone:text-[1.3rem] font-semibold largeTablet:mb-8 smallTablet:mb-7 largePhone:mb-5 smallPhone:mb-3'>We're TheChosenField Academy 👋</span>
                        <p className='largeTablet:text-[1.15rem] smallTablet:text-[1.1rem] smallPhone:text-[1rem] largeTablet:leading-8 smallTablet:leading-7 smallPhone:leading-6 largeTablet:mb-8 smallTablet:mb-7 largePhone:mb-5 smallPhone:mb-4'>Welcome to TheChosenField Academy, where excellence in education meets innovation and personalized learning.</p>
                        <p className='largeTablet:text-[1.15rem] smallTablet:text-[1.1rem] smallPhone:text-[1rem] largeTablet:leading-8 smallTablet:leading-7 smallPhone:leading-6'>Established with a vision to empower students to reach their full potential, we're a premier educational institution specializing in STEM, Humanities, and Social Sciences, offering a wide range of courses tailored to meet the diverse needs of learners across the globe.</p>
                    </div>
                    <div onClick={handleShowAboutVideo} className='largeTablet:w-[52%] rounded-lg overflow-hidden relative group'>
                        <img width="100%" className='rounded-lg mx-auto largeTablet:w-full mediumTablet:w-4/5 smallTablet:w-11/12 smallPhone:w-full transition-transform ease-in-out duration-500 group-hover:scale-105' src="/images/intro-thumbnail.png" alt="" />
                        <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center`}>
                            <button className={`${PlayButtonStyle.playbutton}`}><i className="fa-solid fa-play text-5xl text-white"></i></button>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={sectionRef} className='wideScreen:px-36 desktop:px-28 largeTablet:px-16 largePhone:px-8 smallPhone:px-5 desktop:pt-20 smallTablet:pt-16 largePhone:pt-12 smallPhone:pt-10 desktop:pb-48 largeTablet:pb-44 mediumTablet:pb-24 smallTablet:pb-20 largePhone:pb-16 smallPhone:pb-14 bg-[#303044] text-white flex flex-col items-center'>
                <h1 className='desktop:text-4xl smallTablet:text-3xl smallPhone:text-2xl smallTablet:font-semibold smallPhone:font-medium largeTablet:mb-24 mediumTablet:mb-32 smallTablet:mb-28 largePhone:mb-24 smallPhone:mb-20'>Why Choose Us ?</h1>
                <div className='flex largeTablet:flex-row smallPhone:flex-col relative'>
                    <div className={`w-fit pointer-events-none largeTablet:ml-16 largeTablet:mx-0 smallPhone:mx-auto desktop:w-[19rem] largeTablet:w-64 smallTablet:w-56 largePhone:w-48 smallPhone:w-44 desktop:h-[19rem] largeTablet:h-64 smallTablet:h-56 largePhone:h-48 smallPhone:h-44 bg-[#454557] rounded-full ${(sectionPosition === "top") ? "absolute top-16" : ((sectionPosition === "middle") ? ("fixed top-[35%]") : ((sectionPosition === "bottom") ? ("absolute bottom-0") : ("relative")))} largeTablet:mb-0 mediumTablet:mb-28 smallTablet:mb-24 largePhone:mb-20 smallPhone:mb-20`}>
                        {/* <div className='desktop:w-80 largeTablet:w-64 mediumTablet:w-64 desktop:h-80 largeTablet:h-64 mediumTablet:h-64 bg-[#454557] rounded-full'></div> */}
                        <div style={firstImageStyle} className='absolute -top-[27%] left-[19%] desktop:w-48 largeTablet:w-40 smallTablet:w-36 largePhone:w-28 smallPhone:w-[6.5rem] desktop:h-48 largeTablet:h-40 smallTablet:h-36 largePhone:h-28 smallPhone:h-[6.5rem] p-2 bg-white rounded-full flex items-center justify-center'>
                            <img width="85%" height="85%" className='rounded-full' src="/images/why-choose-us/why-choose-us-1.jpg" alt="" />
                        </div>
                        <div style={secondImageStyle} className='absolute -bottom-5 -left-[25%] desktop:w-48 largeTablet:w-40 smallTablet:w-36 largePhone:w-28 smallPhone:w-[6.5rem] desktop:h-48 largeTablet:h-40 smallTablet:h-36 largePhone:h-28 smallPhone:h-[6.5rem] p-2 bg-white rounded-full flex items-center justify-center'>
                            <img width="85%" height="85%" className='rounded-full' src="/images/why-choose-us/why-choose-us-2.jpg" alt="" />
                        </div>
                        <div style={thirdImageStyle} className='absolute -bottom-5 -right-[25%] desktop:w-48 largeTablet:w-40 smallTablet:w-36 largePhone:w-28 smallPhone:w-[6.5rem] desktop:h-48 largeTablet:h-40 smallTablet:h-36 largePhone:h-28 smallPhone:h-[6.5rem] p-2 bg-white rounded-full flex items-center justify-center'>
                            <img width="85%" height="85%" className='rounded-full' src="/images/why-choose-us/why-choose-us-3.jpg" alt="" />
                        </div>
                    </div>

                    <div className="largeTablet:w-1/2 smallPhone:w-full desktop:space-y-56 largeTablet:space-y-48 mediumTablet:space-y-24 smallTablet:space-y-20 largePhone:space-y-16 smallPhone:space-y-14 largeTablet:ml-auto">
                        <div>
                            <h2 className="desktop:text-4xl largeTablet:text-3xl mediumTablet:text-2xl smallTablet:text-[1.3rem] largePhone:text-[1.2rem] smallPhone:text-[1.1rem] smallPhone:font-medium smallTablet:mb-5 largePhone:mb-3 smallPhone:mb-2">Excellence in Education</h2>
                            <span ref={paraStartRef}></span>
                            <p className="desktop:text-2xl largeTablet:text-[1.3rem] mediumTablet:text-[1.1rem] smallTablet:text-[1rem] largePhone:text-[0.9rem] smallPhone:text-[0.8rem] desktop:leading-[2.6rem] largeTablet:leading-[2.3rem] mediumTablet:leading-[2rem] smallTablet:leading-7 smallPhone:leading-6">At TheChosenField Academy, we are committed to providing an enriching learning experience that goes beyond traditional classroom teaching. Our team of highly qualified educators, each with extensive experience in their respective fields, is dedicated to nurturing intellectual curiosity and fostering a deep understanding of subjects. Whether it's preparing for competitive exams or mastering complex concepts, we offer comprehensive support and guidance to ensure our students excel.</p>
                            <div className='w-full h-[0.5px] bg-[#ffffff1a] mt-8 rounded-full' ref={firstParaRef}></div>
                        </div>

                        <div>
                            <h2 className="desktop:text-4xl largeTablet:text-3xl mediumTablet:text-2xl smallTablet:text-[1.3rem] largePhone:text-[1.2rem] smallPhone:text-[1.1rem] smallPhone:font-medium smallTablet:mb-5 largePhone:mb-3 smallPhone:mb-2">Curriculum Flexibility</h2>
                            <p className="desktop:text-2xl largeTablet:text-[1.3rem] mediumTablet:text-[1.1rem] smallTablet:text-[1rem] largePhone:text-[0.9rem] smallPhone:text-[0.8rem] desktop:leading-[2.6rem] largeTablet:leading-[2.3rem] mediumTablet:leading-[2rem] smallTablet:leading-7 smallPhone:leading-6">Our courses are meticulously designed to cater to various curricula, including GCSE, IGCSE, 1B, AP, and more. We believe in a student-centered approach, emphasizing personalized learning paths that accommodate individual learning styles and paces. This commitment to customization enables us to provide an education that is not only effective but also engaging and enjoyable.</p>
                            <div className='w-full h-[0.5px] bg-[#ffffff1a] mt-8 rounded-full' ref={secondParaRef}></div>
                        </div>
                        <div>
                            <h2 className="desktop:text-4xl largeTablet:text-3xl mediumTablet:text-2xl smallTablet:text-[1.3rem] largePhone:text-[1.2rem] smallPhone:text-[1.1rem] smallPhone:font-medium smallTablet:mb-5 largePhone:mb-3 smallPhone:mb-2">Innovative Learning</h2>
                            <p className="desktop:text-2xl largeTablet:text-[1.3rem] mediumTablet:text-[1.1rem] smallTablet:text-[1rem] largePhone:text-[0.9rem] smallPhone:text-[0.8rem] desktop:leading-[2.6rem] largeTablet:leading-[2.3rem] mediumTablet:leading-[2rem] smallTablet:leading-7 smallPhone:leading-6">We also integrate cutting-edge technology into our teaching methodologies, creating interactive and dynamic learning environments. By leveraging the latest in educational technology, we ensure that our students are not only well-prepared academically but are also equipped with the skills necessary to thrive in an ever-evolving world.</p>
                            <div className='w-full h-[0.5px] bg-[#ffffff1a] mt-8 rounded-full' ref={thirdParaRef}></div>
                        </div>
                    </div>
                </div>
            </section >

            <section className='wideScreen:px-36 desktop:px-28 largeTablet:px-16 largePhone:px-8 smallPhone:px-5 desktop:py-20 largeTablet:py-16 mediumTablet:py-14 smallTablet:py-12 largePhone:py-10 smallPhone:py-8 flex items-center justify-center flex-col bg-white'>
                <h1 className='desktop:text-4xl mediumTablet:text-3xl smallPhone:text-2xl text-font_two largeTablet:font-semibold smallPhone:font-medium desktop:mb-16 largeTablet:mb-12 mediumTablet:mb-10 smallTablet:mb-8 largePhone:mb-6 smallPhone:mb-5'>Our Story</h1>
                <div className='flex items-center justify-center flex-col'>
                    <ul className='flex items-center desktop:space-x-8 largeTablet:space-x-6 mediumTablet:space-x-4 smallPhone:space-x-[0.2rem]'>
                        <li onClick={() => setOurStoryYear("2019")} className={`${ourStoryYear === "2019" ? ourStoryActiveStyle : ourStoryInactiveStyle}`}>2019</li>
                        <span className='desktop:w-16 largeTablet:w-10 mediumTablet:w-8 smallTablet:w-6 largePhone:w-5 smallPhone:w-4 rounded-full smallTablet:h-[2px] smallPhone:h-[1px] bg-font_one'></span>
                        <li onClick={() => setOurStoryYear("2020")} className={`${ourStoryYear === "2020" ? ourStoryActiveStyle : ourStoryInactiveStyle}`}>2020</li>
                        <span className='desktop:w-16 largeTablet:w-10 mediumTablet:w-8 smallTablet:w-6 largePhone:w-5 smallPhone:w-4 rounded-full smallTablet:h-[2px] smallPhone:h-[1px] bg-font_one'></span>
                        <li onClick={() => setOurStoryYear("2023")} className={`${ourStoryYear === "2023" ? ourStoryActiveStyle : ourStoryInactiveStyle}`}>2023</li>
                        <span className='desktop:w-16 largeTablet:w-10 mediumTablet:w-8 smallTablet:w-6 largePhone:w-5 smallPhone:w-4 rounded-full smallTablet:h-[2px] smallPhone:h-[1px] bg-font_one'></span>
                        <li onClick={() => setOurStoryYear("present")} className={`${ourStoryYear === "present" ? ourStoryActiveStyle : ourStoryInactiveStyle}`}>Present</li>
                    </ul>
                    <div>
                        {
                            ourStoryData.map((data, index) => {
                                return (
                                    <div key={index} className={`${ourStoryYear === data.year ? "block animate-fadeinright" : "hidden"} desktop:mt-16 largeTablet:mt-14 mediumTablet:mt-12 smallTablet:mt-10 largePhone:mt-8 smallPhone:mt-6`}>
                                        <div className='flex items-center justify-center mediumTablet:flex-row smallPhone:flex-col desktop:space-x-10 largeTablet:space-x-8 mediumTablet:space-x-6 smallPhone:space-x-0 mediumTablet:space-y-0 smallPhone:space-y-6'>
                                            <div className='mediumTablet:w-1/2 largePhone:w-11/12 smallPhone:w-full flex items-center justify-center'>
                                                <img width={500} className={`${index % 2 === 0 ? 'largeTablet:rounded-ss-[120px] mediumTablet:rounded-ss-[100px] smallTablet:rounded-ss-[120px] smallPhone:rounded-ss-[80px] largeTablet:rounded-ee-[120px] mediumTablet:rounded-ee-[100px] smallTablet:rounded-ee-[120px] smallPhone:rounded-ee-[80px]' : 'largeTablet:rounded-se-[120px] mediumTablet:rounded-se-[100px] smallTablet:rounded-se-[120px] smallPhone:rounded-se-[80px] largeTablet:rounded-es-[120px] mediumTablet:rounded-es-[100px] smallTablet:rounded-es-[120px] smallPhone:rounded-es-[80px]'}`} src={data.image} alt="" />
                                            </div>
                                            <div className='mediumTablet:w-1/2 largePhone:w-11/12 smallPhone:w-full'>
                                                <h2 className='desktop:text-[2rem] largeTablet:text-[1.8rem] smallTablet:text-[1.6rem] largePhone:text-[1.4rem] smallPhone:text-[1.2rem] text-font_two largeTablet:font-semibold smallPhone:font-medium desktop:mb-8 largeTablet:mb-7 smallTablet:mb-4 largePhone:mb-3 smallPhone:mb-2'>{data.title}</h2>
                                                <p className='desktop:text-xl largeTablet:text-[1.2rem] smallTablet:text-[1rem] largePhone:text-[0.9rem] smallPhone:text-[0.8rem] opacity-80'>{data.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            <section className='wideScreen:px-36 desktop:px-28 largeTablet:px-16 largePhone:px-8 smallPhone:px-5 wideScreen:py-20 desktop:py-16 largeTablet:py-14 mediumTablet:py-12 smallTablet:py-10 largePhone:py-8 smallPhone:py-6 flex items-center justify-center flex-col bg-fadeWhite'>
                <h1 className='desktop:text-4xl mediumTablet:text-3xl smallPhone:text-2xl text-font_two largeTablet:font-semibold smallPhone:font-medium desktop:mb-12 mediumTablet:mb-10 largePhone:mb-8 smallPhone:mb-6'>Our Purpose</h1>
                <div className='desktop:space-x-32 largeTablet:space-x-24 mediumTablet:space-x-12 smallPhone:space-x-0 mediumTablet:space-y-0 smallPhone:space-y-10 flex items-center justify-center mediumTablet:flex-row smallPhone:flex-col'>
                    <div className='largeTablet:w-1/2 mediumTablet:w-3/5 smallTablet:w-11/12 smallPhone:w-full desktop:space-y-20 largeTablet:space-y-16 mediumTablet:space-y-12 smallTablet:space-y-8 largePhone:space-y-6 smallPhone:space-y-5'>
                        <p className='desktop:text-[1.3rem] mediumTablet:text-[1.1rem] smallTablet:text-[1rem] largePhone:text-[0.9rem] smallPhone:text-[0.8rem] smallPhone:font-normal desktop:leading-[3rem] largeTablet:leading-[2.5rem] mediumTablet:leading-[2.2rem] smallTablet:leading-[2rem] largePhone:leading-[1.9rem] smallPhone:leading-[1.8rem] opacity-90'>At TheChosenField Academy, education is not just about passing exams; it's about instilling a lifelong love for learning. We are proud to have helped countless students achieve their academic goals and are dedicated to continuing this tradition of excellence.</p>
                        <p className='desktop:text-[1.3rem] mediumTablet:text-[1.1rem] smallTablet:text-[1rem] largePhone:text-[0.9rem] smallPhone:text-[0.8rem] smallPhone:font-normal desktop:leading-[3rem] largeTablet:leading-[2.5rem] mediumTablet:leading-[2.2rem] smallTablet:leading-[2rem] largePhone:leading-[1.9rem] smallPhone:leading-[1.8rem] opacity-90'>Join us at TheChosenField Academy, where we pave the way for future leaders and innovators. Together, let's embark on a journey of knowledge, growth, and success.</p>
                    </div>
                    <div className='largeTablet:w-1/2 mediumTablet:w-2/5 smallTablet:w-11/12 smallPhone:w-full flex items-center justify-center flex-col'>
                        <img className='desktop:rounded-ss-[120px] largeTablet:rounded-ss-[100px] mediumTablet:rounded-ss-[80px] smallTablet:rounded-ss-[100px] largePhone:rounded-ss-[80px] smallPhone:rounded-ss-[60px] desktop:rounded-ee-[120px] largeTablet:rounded-ee-[100px] mediumTablet:rounded-ee-[80px] smallTablet:rounded-ee-[100px] largePhone:rounded-ee-[80px] smallPhone:rounded-ee-[60px] desktop:mb-8 mediumTablet:mb-6 smallTablet:mb-6 largePhone:mb-5 smallPhone:mb-4' width={450} src="images/sajjad.png" alt="" />
                        <h3 className="smallTablet:text-xl largePhone:text-lg smallPhone:text-base text-font_one font-semibold mb-1">Sajad Ahmed Qureshi</h3>
                        <h4 className="smallTablet:text-lg largePhone:text-base smallPhone:text-sm text-font_two font-medium text-center">Founder & Educator, TheChosenField Academy</h4>
                    </div>
                </div>
            </section>

            <section className='wideScreen:px-36 desktop:px-28 largeTablet:px-16 largePhone:px-8 smallPhone:px-5 desktop:py-14 largeTablet:py-12 mediumTablet:py-10 smallTablet:py-8 largePhone:py-6 smallPhone:py-5 flex items-center justify-center flex-col bg-font_one'>
                <h1 className='largeTablet:text-2xl mediumTablet:text-[1.3rem] largePhone:text-[1.2rem] smallPhone:text-[1rem] text-white mediumTablet:font-semibold smallPhone:font-medium largeTablet:mb-8 mediumTablet:mb-6 largePhone:mb-5 smallPhone:mb-4 text-center'>Looking for Expert Guidance? Inquire About Tutoring Today!</h1>
                <ScrollToTopNavLink to="/tutoring-enquire">
                    <button className="text-white bg-primary mediumTablet:text-lg smallPhone:text-base font-medium mediumTablet:py-3 largePhone:py-2 smallPhone:py-[6px] largeTablet:px-24 mediumTablet:px-20 largePhone:px-16 smallPhone:px-12 rounded-full transition ease-in-out delay-150 hover:bg-[#19a095]">Enquire Now</button>
                </ScrollToTopNavLink>
            </section>

            <section className='wideScreen:px-36 desktop:px-28 largeTablet:px-16 largePhone:px-8 smallPhone:px-5 pb-16 flex items-center justify-center'>
                <Quote quote="If kids can be super engaged in video games, there's a way for them to be super engaged in education as well." name="Elon Musk" />
            </section>
        </>
    );
}

export default About;