import FeaturedCourses from "../../components/FeaturedCourses/FeaturedCourses";
import Brand from "../../components/Header/Brand/Brand";
import Header from "../../components/Header/Header";
import InfographicsCounter from "../../components/InfographicsCounter/InfographicsCounter";
import MarqueeSlider from "../../components/MarqueeSlider/MarqueeSlider";
import OfferBanner from "../../components/OfferBanner/OfferBanner";
// import OneOnOne from "../../components/OneOnOne/OneOnOne";
// import OurTeam from "../../components/OurTeam/OurTeam";
import Testimonials from "../../components/Testimonials/Testimonials";
import WhyTheChosenField from "../../components/WhyTheChosenField/WhyTheChosenField";

const Home = () => {
    return (
        <main>
            <section>
                <Header>
                    <Brand />
                </Header>
            </section>

            <MarqueeSlider />
            <section className="wideScreen:px-12 desktop:px-10 largeTablet:px-8 mediumTablet:px-10 smallTablet:px-8 largePhone:px-6 smallPhone:px-3 largeTablet:py-20 mediumTablet:py-16 smallTablet:py-12 smallPhone:py-10 bg-fadeWhite">
                <OfferBanner />
            </section>
            <WhyTheChosenField />
            <FeaturedCourses />
            <Testimonials />
            {/* <OneOnOne /> */}
            {/* <OurTeam /> */}
            <InfographicsCounter />
        </main>
    );
}

export default Home;