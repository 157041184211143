import { useEffect, useRef, useState } from "react";

const InfographicsCard = ({ image, number, text }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [currentCount, setCurrentCount] = useState(0);
    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1, // Trigger when at least 10% of the component is visible
            }
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isVisible) {
            let start = 0;
            const duration = 1500; // Duration of the animation in ms
            const increment = number / duration * 16; // Increment per frame (assuming ~60fps)

            const updateCounter = () => {
                start += increment;
                if (start < number) {
                    setCurrentCount(Math.ceil(start));
                    requestAnimationFrame(updateCounter);
                } else {
                    setCurrentCount(number); // Ensure the final value is set correctly
                }
            };

            requestAnimationFrame(updateCounter);
        }
    }, [isVisible, number]);

    return (
        <div style={{ boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px" }} className="bg-white p-6 rounded-lg">
            <img className="smallTablet:w-12 smallPhone:w-10 mb-3" src={`/images/infographics/${image}`} alt="countries covered" />
            <div ref={counterRef} className="mediumTablet:text-4xl smallPhone:text-3xl font-semibold text-font_two mb-1">{currentCount}{number % 1 !== 0 ? "*" : "+"}</div>
            <p className="smallTablet:text-base smallPhone:text-sm font-medium opacity-90">{text}</p>
        </div>
    );
}

export default InfographicsCard;