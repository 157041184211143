import { NavLink } from 'react-router-dom';
import ScrollToTopNavLink from '../../ScrollToTopNavLink/ScrollToTopNavLink';
import Drawer from '@mui/material/Drawer';
import './Brand.css';
import { useState } from 'react';
import { Tooltip } from '@mui/material';

const Brand = () => {
    const [openSocialDrawer, setOpenSocialDrawer] = useState(false);

    const toggleSocialDrawer = (newOpen) => () => {
        setOpenSocialDrawer(newOpen);
    };
    return (
        <div className="flex items-center py-5">
            <ScrollToTopNavLink className="flex flex-col items-center" to="/">
                {/* <span className="text-white largeTablet:text-3xl largePhone:text-2xl smallPhone:text-base font-semibold transition ease-in delay-150">TheChosenField</span>
                <small className='text-primary largeTablet:text-xl largePhone:text-lg smallPhone:text-sm font-medium'>Academy</small> */}
                <img className="largeTablet:h-[3.8rem] mediumTablet:h-[3rem] largePhone:h-[3rem] smallPhone:h-[2.9rem]" src="/images/TheChosenField_Academy_Logo.png" alt="TheChosenField Logo" />
            </ScrollToTopNavLink>

            <div className="ml-auto flex items-center desktop:space-x-20 largeTablet:space-x-12 mediumTablet:space-x-6 smallTablet:space-x-4">
                <span className="text-white smallTablet:flex smallPhone:hidden items-center">
                    <i className="fa-solid fa-envelope text-base"></i>
                    <span className="largeTablet:text-lg mediumTablet:text-base smallTablet:text-sm mediumTablet:ml-3 smallTablet:ml-1">
                        <div className="font-normal text-[0.9rem]">E-mail Us</div>
                        <div className="font-normal text-[0.9rem]">sajad@thechosenfield.org</div>
                    </span>
                </span>
                <span className="text-primary flex items-center justify-center desktop:space-x-4 largeTablet:space-x-3 mediumTablet:space-x-2 largePhone:space-x-2 smallPhone:space-x-1 smallTablet:text-base largePhone:text-sm smallPhone:text-xs">
                    <div className="circle-wrapper relative inline-block largeTablet:w-12 smallPhone:w-10 largeTablet:h-12 smallPhone:h-10">
                        <div className='circle w-full h-full border-dotted border-[2.5px] border-white p-[2px] rounded-full'></div>
                        <Tooltip title="WhatsApp" placement="bottom-start">
                            <NavLink to="https://wa.me/+917051144137" target="_blank">
                                <i className="fa-brands fa-whatsapp absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                            </NavLink>
                        </Tooltip>
                    </div>
                    <Tooltip title="Youtube" placement="bottom-start">
                        <NavLink to="https://www.youtube.com/@Viawave/" target="_blank"><i className="fa-brands fa-youtube largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i></NavLink>
                    </Tooltip>
                    <div className='mediumTablet:inline-block smallPhone:hidden'>
                        <Tooltip title="Instagram" placement="bottom-start">
                            <NavLink to="https://www.instagram.com/thechosenfield/" target="_blank">
                                <i className="fa-brands fa-square-instagram largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                            </NavLink>
                        </Tooltip>
                    </div>
                    <div className='mediumTablet:inline-block smallPhone:hidden'>
                        <Tooltip title="LinkedIn" placement="bottom-start">
                            <NavLink to="https://www.linkedin.com/company/thechosenfield/" target="_blank">
                                <i className="fa-brands fa-linkedin largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                            </NavLink>
                        </Tooltip>
                    </div>
                    <div className='mediumTablet:inline-block smallPhone:hidden'>
                        <Tooltip title="X" placement="bottom-start">
                            <NavLink to="https://twitter.com/qureshisajjad21" target="_blank">
                                <i className="fa-brands fa-square-x-twitter largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                            </NavLink>
                        </Tooltip>
                    </div>
                    <div className='mediumTablet:inline-block smallPhone:hidden'>
                        <Tooltip title="Blogger" placement="bottom-start">
                            <NavLink to="https://thechosenfieldacademy.blogspot.com/" target="_blank">
                                <i className="fa-brands fa-blogger largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                            </NavLink>
                        </Tooltip>
                    </div>
                    <div className='mediumTablet:inline-block smallPhone:hidden'>
                        <Tooltip title="Quora" placement="bottom-start">
                            <NavLink to="http://www.quora.com/profile/Sajjad-Qureshi-13?ch=2&oid=246599219&srid=hJROR&target_type=user" target="_blank">
                                <i className="fa-brands fa-quora largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                            </NavLink>
                        </Tooltip>
                    </div>
                    <button onClick={toggleSocialDrawer(true)} className='mediumTablet:hidden smallPhone:inline-block'>
                        <i className="fa-solid fa-bars-staggered rotate-180 largeTablet:p-3 smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                    </button>
                </span>
            </div>
            <div className='mediumTablet:hidden smallPhone:inline-block'>
                <Drawer PaperProps={{ sx: { backgroundColor: "#94C5CA", color: '#1eb2a6', height: '280px' } }} anchor='right' open={openSocialDrawer} onClose={toggleSocialDrawer(false)}>
                    <div className='flex flex-col items-center justify-center h-full space-y-5 p-2'>
                        <NavLink to="https://www.instagram.com/thechosenfield/" target="_blank">
                            <i className="fa-brands fa-square-instagram smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                        </NavLink>
                        <NavLink to="https://www.linkedin.com/company/thechosenfield/" target="_blank">
                            <i className="fa-brands fa-linkedin smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                        </NavLink>
                        <NavLink to="https://twitter.com/qureshisajjad21" target="_blank">
                            <i className="fa-brands fa-twitter smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                        </NavLink>
                        <NavLink to="https://thechosenfieldacademy.blogspot.com/" target="_blank">
                            <i className="fa-brands fa-blogger smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                        </NavLink>
                        <NavLink to="http://www.quora.com/profile/Sajjad-Qureshi-13?ch=2&oid=246599219&srid=hJROR&target_type=user" target="_blank">
                            <i className="fa-brands fa-quora smallPhone:p-2 rounded-full bg-transparent_blur transition ease-in delay-150 hover:text-white hover:bg-primary"></i>
                        </NavLink>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}

export default Brand;