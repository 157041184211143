import { useEffect, useRef, useState } from "react";
import Section from "../Section/Section";
import TestimonialCard from "./TestimonialCard/TestimonialCard";
import TestimonialSlider from "./TestimonialSlider/TestimonialSlider";
import { SwiperSlide } from 'swiper/react';
import data from '../../testimonialsData.json';
import Slider from "../shared/Slider/Slider";

const Testimonials = () => {
    const [threeSplitData, setThreeSplitData] = useState([[], [], []]);
    const [twoSplitData, setTwoSplitData] = useState([[], []]);

    const secondChildOfThreeSliders = useRef(null);
    const secondChildOfTwoSliders = useRef(null);

    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    useEffect(() => {
        // Shuffle the data randomly
        const shuffledData = shuffleArray([...data]);

        if (window.innerWidth >= 1024) {
            // Calculate the size of each part
            const partSize = Math.floor(shuffledData.length / 3);
            const remainder = shuffledData.length % 3;

            // Split the data into 3 parts
            const firstPart = shuffledData.slice(0, partSize);
            const secondPart = shuffledData.slice(partSize, partSize * 2);
            const thirdPart = shuffledData.slice(partSize * 2);

            // Add remaining data to the third part
            if (remainder > 0) {
                thirdPart.push(...shuffledData.slice(-remainder));
            }

            setThreeSplitData([firstPart, secondPart, thirdPart]);

            secondChildOfThreeSliders.current.style.marginRight = '1.25rem';
            secondChildOfThreeSliders.current.style.marginLeft = '1.25rem';
        } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
            // Calculate the size of each part
            const partSize = Math.floor(shuffledData.length / 2);
            const remainder = shuffledData.length % 2;

            // Split the data into 2 parts
            const firstPart = shuffledData.slice(0, partSize);
            const secondPart = shuffledData.slice(partSize);

            // Add remaining data to the second part
            if (remainder > 0) {
                secondPart.push(...shuffledData.slice(-remainder));
            }

            setTwoSplitData([firstPart, secondPart]);
            secondChildOfTwoSliders.current.style.marginLeft = '1.25rem';
        }

    }, []);

    return (
        <Section heading="Testimonials" subHeading="What our Student Says" bgColor="#ffffff">
            <div className="h-[48rem] relative hidden largeTablet:flex">
                <div className="flex-1">
                    <TestimonialSlider>
                        {
                            threeSplitData[0].map((testimonial) => (
                                <SwiperSlide key={testimonial._id}>
                                    <TestimonialCard comment={testimonial.comment} designation={testimonial.designation} name={testimonial.name} course={testimonial.course} />
                                </SwiperSlide>
                            ))
                        }
                    </TestimonialSlider>
                </div>

                <div className="flex-1" ref={secondChildOfThreeSliders}>
                    <TestimonialSlider>
                        {
                            threeSplitData[1].map((testimonial) => (
                                <SwiperSlide key={testimonial._id}>
                                    <TestimonialCard comment={testimonial.comment} designation={testimonial.designation} name={testimonial.name} course={testimonial.course} />
                                </SwiperSlide>
                            ))
                        }
                    </TestimonialSlider>
                </div>

                <div className="flex-1">
                    <TestimonialSlider>
                        {
                            threeSplitData[2].map((testimonial) => (
                                <SwiperSlide key={testimonial._id}>
                                    <TestimonialCard comment={testimonial.comment} designation={testimonial.designation} name={testimonial.name} course={testimonial.course} />
                                </SwiperSlide>
                            ))
                        }
                    </TestimonialSlider>
                </div>

                <div className="absolute slider-fadein-blur-to-top w-full h-64 bg-white bottom-0 z-10 pointer-events-none"></div>
                {/* custom css: slider-fadein-blur-to-top */}
            </div>

            <div className="h-[48rem] relative largeTablet:hidden mediumTablet:flex hidden">
                <div className="flex-1">
                    <TestimonialSlider>
                        {
                            twoSplitData[0].map((testimonial) => (
                                <SwiperSlide key={testimonial._id}>
                                    <TestimonialCard comment={testimonial.comment} designation={testimonial.designation} name={testimonial.name} course={testimonial.course} />
                                </SwiperSlide>
                            ))
                        }
                    </TestimonialSlider>
                </div>

                <div className="flex-1" ref={secondChildOfTwoSliders}>
                    <TestimonialSlider>
                        {
                            twoSplitData[1].map((testimonial) => (
                                <SwiperSlide key={testimonial._id}>
                                    <TestimonialCard comment={testimonial.comment} designation={testimonial.designation} name={testimonial.name} course={testimonial.course} />
                                </SwiperSlide>
                            ))
                        }
                    </TestimonialSlider>
                </div>

                <div className="absolute slider-fadein-blur-to-top w-full h-64 bg-white bottom-0 z-10 pointer-events-none"></div>
                {/* custom css: slider-fadein-blur-to-top */}
            </div>

            <div className="w-full relative mediumTablet:hidden block">
                <Slider slidesPerView={1} sliderClass="align-middle w-[97%] mt-8" autoPlay={true} spaceBetween={30} arrowLeftPosition="1px" arrowLeftStyle="text-font_one -top-3 smallTablet:translate-x-62 largePhone:translate-x-32 smallPhone:translate-x-12" arrowRightPosition="1px" arrowRightStyle="text-font_one -top-3 smallTablet:-translate-x-62 largePhone:-translate-x-32 smallPhone:-translate-x-12" height="">
                    {
                        data.map((testimonial) => (
                            <SwiperSlide key={testimonial._id}>
                                <TestimonialCard comment={testimonial.comment} designation={testimonial.designation} name={testimonial.name} course={testimonial.course} />
                            </SwiperSlide>
                        ))
                    }
                </Slider>
            </div>
        </Section>
    )
}

export default Testimonials;