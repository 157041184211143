import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Mousewheel, FreeMode, Autoplay } from 'swiper/modules';

const TestimonialSlider = ({ children }) => {
    return (
        <div className="overflow-hidden">
            <Swiper
                direction={'vertical'}
                slidesPerView={3}
                spaceBetween={20}
                speed={1000}
                modules={[Mousewheel, FreeMode, Autoplay]}
                mousewheel={true}
                freeMode={true}
                loop={true}
                grabCursor={true}
                autoplay={{ 
                    delay: 2500,
                    pauseOnMouseEnter: true,
                    reverseDirection: false
                }}
                className="mySwiper w-full h-[48rem]"
            >
                {children}
            </Swiper>
        </div>
    )
}

export default TestimonialSlider;