import CustomizedAccordions from "../../components/Accordion/Accordion";
import Brand from "../../components/Header/Brand/Brand";
import Header from "../../components/Header/Header";
import Quote from "../../components/shared/Quote/Quote";


const OurOfferings = () => {

    return (
        <>
            <section>
                <Header>
                    <Brand />
                </Header>
            </section>

            <div className='wideScreen:px-36 desktop:px-24 smallTablet:px-16 smallPhone:px-6 desktop:py-32 smallTablet:py-16 smallPhone:py-14 space-y-2'>
                <CustomizedAccordions />
                <Quote quote="You cannot teach a man anything; you can only help him find it within himself." name="Galileo Galilei" />
            </div>
        </>
    )
}

export default OurOfferings;
