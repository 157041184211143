import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const TestimonialCard = ({ comment, name, designation, course }) => {
    return (
        <div style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset" }} className='rounded-md bg-white'>
            <FormatQuoteIcon sx={{ fontSize: '3.3rem', marginLeft: '6px' }} className='text-primary rotate-180' />
            <div className='mx-4 pb-4 border-b-2 border-[#e2e2e2]'>
                <p className="text-base text-center font-medium opacity-90">{comment}</p>
            </div>
            <div className='px-4 pt-3 pb-4 flex flex-col'>
                <span className='font-semibold'>{name}</span>
                <span className='text-xs opacity-90 mt-1'>{designation}{course === null ? '' : `, (${course})`}</span>
            </div>
        </div>
    )
}

export default TestimonialCard;