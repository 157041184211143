import { NavLink } from "react-router-dom";
import './OfferBannerButton.css';

const OfferBanner = () => {
    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px' }} className="relative bg-font_two overflow-hidden rounded-lg w-full largeTablet:h-[26rem] mediumTablet:h-[24rem] smallTablet:h-[22rem] largePhone:h-[20rem] smallPhone:h-[18rem] p-10">
            <div className="absolute desktop:left-[7rem] largeTablet:left-[5rem] largeTablet:inline-block smallPhone:hidden">
                <img className="desktop:w-[21rem] largeTablet:w-[17.5rem]" src="/images/banner/2.png" alt="" />
                <img className="desktop:w-72 largeTablet:w-60 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50" src="/images/banner/1.png" alt="" />
            </div>
            <div>
                <img className="absolute desktop:w-[30rem] largeTablet:w-[26rem] desktop:bottom-[7.5rem] largeTablet:bottom-[7rem] desktop:-left-44 largeTablet:-left-48 largeTablet:inline-block smallPhone:hidden" src="/images/banner/3.png" alt="" />
                <img className="absolute desktop:w-[26rem] smallPhone:w-[24rem] desktop:-bottom-[8rem] largeTablet:-bottom-[7rem] smallTablet:-bottom-[9rem] largePhone:-bottom-[10.5rem] smallPhone:-bottom-[8.5rem] largeTablet:-left-10 smallTablet:-left-[5.5rem] largePhone:-left-[7.5rem] smallPhone:-left-[6rem] z-10" src="/images/banner/5.png" alt="" />
                <img className="absolute desktop:w-[30rem] smallPhone:w-[26rem] largeTablet:bottom-0 smallTablet:-bottom-[10rem] largePhone:-bottom-[12rem] smallPhone:-bottom-[6rem] largeTablet:-left-44 largePhone:-left-[16rem] smallPhone:-left-[15rem]" src="/images/banner/4.png" alt="" />
                <img className="absolute smallTablet:w-6 largePhone:w-4 smallPhone:w-3 smallTablet:bottom-24 largePhone:bottom-16 smallPhone:bottom-14" src="/images/banner/7.png" alt="" />
                <img className="absolute w-28 largeTablet:-top-[4.5rem] smallTablet:-top-12 smallPhone:-top-16 largeTablet:left-1/2 smallTablet:-left-12 smallPhone:-left-16" src="/images/banner/9.png" alt="" />
                <img className="absolute w-28 smallTablet:-right-12 smallPhone:-right-16 smallTablet:-bottom-12 smallPhone:-bottom-16" src="/images/banner/9.png" alt="" />
                <img className="absolute w-80 smallTablet:-right-40 largePhone:-right-40 smallPhone:-right-[9.5rem] smallTablet:-top-24 largePhone:-top-[7.5rem] smallPhone:-top-[7.5rem]" src="/images/banner/6.png" alt="" />
                <img className="absolute w-[22rem] smallTablet:-right-20 largePhone:-right-28 smallPhone:-right-24 smallTablet:-top-32 largePhone:-top-[9.5rem] smallPhone:-top-[8.5rem]" src="/images/banner/5.png" alt="" />
                <img className="absolute w-14 largeTablet:left-[28rem] smallTablet:left-[10rem] smallPhone:left-[6rem] largeTablet:top-16 smallPhone:top-10" src="/images/banner/8.png" alt="" />
                <img className="absolute w-14 largeTablet:left-[24rem] smallTablet:left-[16rem] largePhone:left-[10rem] smallPhone:left-[6rem] largeTablet:bottom-14 smallTablet:bottom-10 largePhone:bottom-8 smallPhone:bottom-4" src="/images/banner/8.png" alt="" />
                <img className="absolute w-14 largePhone:right-10 smallPhone:right-6 largeTablet:top-28 largePhone:top-48 smallPhone:top-44" src="/images/banner/8.png" alt="" />
            </div>
            <div className="absolute largeTablet:top-1/4 largePhone:top-[40%] smallPhone:top-[40%] largeTablet:right-10 largeTablet:left-auto smallPhone:left-1/2 largeTablet:-translate-x-0 smallPhone:-translate-x-1/2 largeTablet:-translate-y-0 smallPhone:-translate-y-1/2 largeTablet:w-auto smallPhone:w-full">
                <div className="largePhone:mt-7 smallPhone:mt-[1.8rem]">
                    <img className="wideScreen:w-[50rem] desktop:w-[42rem] largeTablet:w-[32rem] mediumTablet:w-[35rem] smallTablet:w-[32rem] largePhone:w-[25.5rem] smallPhone:w-[18rem] mx-auto" src="/images/banner/10.png" alt="" />
                    <h1 className="w-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 wideScreen:text-[3rem] desktop:text-[2.6rem] mediumTablet:text-[2rem] smallTablet:text-[1.8rem] largePhone:text-[1.6rem] smallPhone:text-[1.2rem] font-semibold text-font_one text-center">Your Success Starts Here !!</h1>
                </div>
                <p className="smallTablet:mt-3 smallPhone:mt-2 text-center desktop:text-xl smallTablet:text-lg largePhone:text-base smallPhone:text-sm font-light opacity-90 text-white">Book a Free Demo and Learn from the Best!</p>
                <div className="absolute left-1/2 largeTablet:-bottom-32 mediumTablet:-bottom-28 smallTablet:-bottom-24 largePhone:-bottom-24 smallPhone:-bottom-20 -translate-x-1/2 -translate-y-1/2">
                    <NavLink to="https://wa.me/+917051144137" target="_blank">
                        <button className="btn desktop:py-4 smallTablet:py-3 largePhone:py-2 smallPhone:py-2 wideScreen:px-24 desktop:px-20 smallTablet:px-16 largePhone:px-10 smallPhone:px-4 desktop:text-xl smallTablet:text-lg largePhone:text-lg smallPhone:text-base">Book a Demo</button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default OfferBanner;